<template>
  <v-app>
    <main>
      <v-img
        class="fill-height"
        :aspect-ratio="16 / 9"
        height="100vh"
        width="100vw"
      >
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6" xl="4">
              <v-card
                color="rgb(222 222 222 / 20%)"
                elevation="12"
                class="py-8"
              >
                <v-card-text class="text-center">
                  <h2 class="text-h4 black--text">
                    パスワード再設定
                  </h2>
                  <v-form
                    @submit.stop="submit"
                    action="javascript:void(0);"
                    v-model="isValid"
                  >
                    <div class="mt-6">
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            v-model="email"
                            :rules="rules.email"
                            label="登録されたメールアドレスを入力してください。"
                            name="email"
                            outlined
                            prepend-inner-icon="mdi-account-outline"
                            hide-details="auto"
                          ></v-text-field>

                          <div
                            class="d-flex mt-2"
                            v-if="inValidMsg.message != ''"
                          >
                            <span
                              :class="
                                inValidMsg.status ? 'green--text' : 'red--text'
                              "
                              >{{ inValidMsg.message }}</span
                            >
                          </div>

                          <div class="d-flex align-center justify-end mt-4">
                            <!-- <v-checkbox label="Remember me"></v-checkbox> -->
                            <router-link :to="{ name: 'Login' }"
                              >ログインに戻る</router-link
                            >
                          </div>

                          <div class="mt-6">
                            <v-btn
                              block
                              class="text-capitalize"
                              large
                              :loading="loading"
                              @click="submit"
                              color="primary"
                              :disabled="!isValid"
                              >パスワード再発行メールを送信</v-btn
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </main>
  </v-app>
</template>

<script>
import api from '@/services/api-methods'
export default {
  name: 'ForgotPassword',
  data() {
    return {
      loading: false,
      email: null,
      isValid: true,
      inValidMsg: {},
      rules: {
        required: v => !!v || 'Required.',
        email: [
          v => !!v || 'メールアドレスが必要です。',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'メールアドレスを正しくご入力ください。'
        ]
      }
    }
  },

  methods: {
    submit() {
      this.inValidMsg = {}
      this.loading = true
      let postData = {
        email: this.email
      }
      api
        .post('/forgot-password', postData)
        .then(res => {
          let response = res.data
          this.loading = false
          this
          this.inValidMsg = {
            status: response.status,
            message: response.message
          }
        })
        .catch(err => {
          this.isValid = false
          this.$swal.fire('アクションなし！', err, 'error')
          this.loading = false
          this.inValidMsg = {
            status: false,
            message: 'このメールアドレスは登録されていません。'
          }
        })
    }
  }
}
</script>
<style></style>
